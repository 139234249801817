<i18n src="@/locales/testimonial69.json" />
<template>
  <div class="fixed-background">
    <ImanTestimonialHeader :img="img">
      <h1>{{ $t('h1_title_1') }}</h1>
      <p>
        {{ $t('header_p1') }}
      </p>
    </ImanTestimonialHeader>
    <ImanTestimonialMessage type="is-info">
      <div v-html="$t('message_1')" />
    </ImanTestimonialMessage>
    <ImanTestimonialArticle :title="$t('h2_title_1')">
      <div class="columns is-vcentered">
        <div class="column is-3">
          <img
            src="@/assets/img/testimonial/testimonial69/parodontite-generalisee-implants-basaux.jpg"
            alt="Parodontite chez les fumeurs"
          >
        </div>
        <div class="column">
          <p>
            {{ $t('article_p1_1') }}
          </p>
        </div>
      </div>
      <div class="columns is-vcentered">
        <div class="column is-3">
          <img
            src="@/assets/img/testimonial/testimonial69/Parodontite-dechaussement-dents.jpg"
            alt="Dents qui bougent et tombent"
          >
        </div>
        <div class="column">
          <p>
            {{ $t('article_p1_2') }}
          </p>
          <ImanMoreInfo :path="$t('more_info_path_1')">
            {{ $t('more_info_1') }}
          </ImanMoreInfo>
        </div>
      </div>
    </ImanTestimonialArticle>
    <ImanTestimonialArticle :title="$t('h2_title_2')">
      <div class="columns is-vcentered">
        <div class="column is-3">
          <img
            src="@/assets/img/testimonial/testimonial69/prothese-dentaire-fixe-implants-basals.jpg"
            alt="Avis sur la technique basale avec dents qui bougent"
          >
        </div>
        <div class="column">
          <p>
            {{ $t('article_p2') }}
          </p>
        </div>
      </div>
    </ImanTestimonialArticle>
    <ImanTestimonialArticle :title="$t('h2_title_3')">
      <div class="columns is-vcentered">
        <div class="column is-3">
          <img
            src="@/assets/img/testimonial/testimonial69/protheses-fixe-implants-dentaires.jpg"
            alt="Implant dentaire basal chez un fumeur"
          >
        </div>
        <div class="column">
          <p>
            {{ $t('article_p3') }}
          </p>
        </div>
      </div>
    </ImanTestimonialArticle>
    <section class="section">
      <div class="columns is-centered">
        <div class="column is-half">
          <ImanVideo :video="video" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {metaMixin} from "@/mixins/meta-mixin";

export default {
  name: "Testimonial69",
  mixins: [metaMixin],
  props: {
    locale: {
      type: String,
      default: 'en'
    }
  },
  data () {
    return {
      video: {
        src: 'https://www.youtube.com/embed/ebgje30wrEg'
      },
      img: {
        path: 'testimonial/testimonial69/miniature_brigitte.jpg',
        alt: ''
      }
    }
  },
  computed: {
    openGraphImgPath () {
      return 'https://anveli.dental' + require('../../assets/img/thumbnail/miniature_69.jpg')
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/style/scss/variables';
.card {
  margin: 1rem 2rem;
  background-color: rgba(255, 255, 255, 0.70);
  .card-header-title {
    background-color: rgba(224, 32, 64, 0.70);
    font-family: 'Comfortaa', cursive;
    color: white;
  }
  .card-content {
    text-align: justify;
  }
}
</style>
